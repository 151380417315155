const fetchUserById = async (email, password) => {
  try {
    const response = await fetch("https://jsonplaceholder.typicode.com/todos")
      .then((response) => response.json())
      .then((json) => json);
    console.log("response is here", response);
    return response;
  } catch (error) {
    throw error;
  }

  // try {
  //   const { data } = await axios.post(REACT_APP_USER_API_URL + "auth/signup/email", {
  //     email: email,
  //     password: password,
  //   });
  //   return data;
  // } catch (error: any) {
  //   throw error;
  // }
};

const plantService = {
  fetchUserById,
};

export default plantService;
