import { combineReducers, configureStore } from "@reduxjs/toolkit";
import plantReducer from "../features/plant/plantSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// store without redux persistence
// export const store = configureStore({
//   reducer: {
//     plantDetail: plantReducer,
//   },
// });

// store with redux persistence
const persistConfig = {
  key: "ReduxStore",
  storage,
};
// there is two way to create a store

// first way
// export const store = configureStore({
//   reducer: {
//     user: plantReducer,
//   },
// });

// second way
const rootReducer = combineReducers({
  plant: plantReducer,
  // notes: NotesReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

// use this all for all way
export const persistor = persistStore(store);
