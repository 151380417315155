import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import plantAction from "./plantAction";

const initialState = {
  entities: {},
  selectedPlantId: [],
  plantIdDetails: [],
  navbarMenus: [],
  combinedPlants: null,
};

const plantSlices = createSlice({
  name: "plant",
  initialState: initialState,
  reducers: {
    addPlantId: (state, action) => {
      state.selectedPlantId = action.payload;
    },
    addPlantIdDetails: (state, action) => {
      state.plantIdDetails = action.payload;
    },
    combinedSelected: (state, action) => {
      state.combinedPlants = action.payload;
    },
    reset: (state) => {
      state.entities = {};
      state.selectedPlantId = [];
      state.plantIdDetails = [];
      state.combinedPlants = null;
    },
    addNavbarMenu: (state, action) => {
      state.navbarMenus = action.payload;
    },
  },
  extraReducers: {
    [plantAction.fetchUserById.fulfilled.toString()]: (state, action) => {
      state.entities.isLoading = false;
      state.entities.isSuccess = true;
      state.entities.data = action.payload;
    },
    [plantAction.fetchUserById.pending.toString()]: (state) => {
      state.entities.isLoading = true;
    },
    [plantAction.fetchUserById.rejected.toString()]: (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.error = action.error;
      // toast.error(`Error signing up: ${action.error.message}`, TOASTIFY_THEME);
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchUserById.pending, (state) => {
  //       state.entities.isLoading = true;
  //     })
  //     .addCase(fetchUserById.fulfilled, (state, action) => {
  //       state.entities.isLoading = false;
  //       state.entities.isSuccess = true;
  //       state.entities.data = action.payload;
  //     })
  //     .addCase(fetchUserById.rejected, (state, action) => {
  //       state.entities.isLoading = false;
  //       state.entities.isError = true;
  //       state.entities.message = action.payload;
  //       state.entities.user = null;
  //     });
  // },
});

export const {
  reset,
  addPlantId,
  addPlantIdDetails,
  addNavbarMenu,
  combinedSelected,
} = plantSlices.actions;
export default plantSlices.reducer;
