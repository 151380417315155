import { createAsyncThunk } from "@reduxjs/toolkit";
import plantService from "./plantService";

export const fetchUserById = createAsyncThunk(
  "fetchUserById",
  async (userId) => {
    try {
      const response = await plantService.fetchUserById();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const plantAction = {
  fetchUserById,
};

export default plantAction;
